import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import axios from 'axios';
import { api } from 'helper/api/client';
import MenuContext from 'context/menu';
import { useHistory } from 'react-router-dom';
import Icon from 'components/ui/Icon';
import { getNextIndex } from 'helper/dropdown';
import log from 'helper/log';
import Loading from 'components/view/Loading';
import permission from 'helper/permission';
import url from 'helper/url';

let debounceTimeout;

const HeaderSearch = (props) => {
    const history = useHistory();
    const menuContext = useContext(MenuContext);
    const minLength = 2;
    const [term, setTerm] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const searchInputRef = useRef(null);

    const doSearch = (event) => {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
            const value = event.target.value.trim();
            setTerm(value);
        }, 300);
    }

    const getTitle = (result) => result.title;
    const getLink = useCallback((result) => {
        if (result.type === 'pages') {
            return url.get('page', result.slug);
        } else if (result.type === 'faqs') {
            return url.get('faq', result.slug);
        } else {
            return '/' + result.slug;
        }
    }, []);
    const resultClick = (event) => {
        event.preventDefault();
        props.setSearchOpen(false);
        menuContext.setMenuOpen(false);
        history.push(results[event.target.dataset.index].url);
    }

    const getResults = () => {
        return (
            <ul className="header__search-results">
                { results.length === 0 && !loading && <li className="header__search-results-list-item header__search-results-list-item--no-results"><Icon icon="Info" />There are no results for your search <strong>"{ term }"</strong></li> }
                { loading && <li className="header__search-results-list-item header__search-results-list-item--loading"><Loading colour="blue" /></li> }
                { !loading && results.length > 0 && results.map((result, index) => {
                    return <li key={ index } className={ 'header__search-results-list-item' + (activeIndex === index ? ' header__search-results-list-item--active' : '') }><button onClick={ resultClick } data-index={ index } className="header__search-results-list-item-link">{ getTitle(result) }</button></li>
                }) }
            </ul>
        );
    }
    const getFilteredResults = useCallback((results) => {
        let filteredResults = [];
        let foundUrls = [];
        let url;
        let valid;
        // Prevent duplicate URLs
        for (const result of results) {
            url = getLink(result);
            if (!foundUrls.includes(url)) {
                valid = ((result.type === 'navigations' && permission.validRoute(url)) || result.type !== 'navigations');
                if ((process.env.REACT_APP_NAV_TEST_MODE && process.env.REACT_APP_NAV_TEST_MODE === 'true') || valid) {
                    filteredResults.push({url, title: result.title + (valid ? '' : ' *'), type: result.type});
                    foundUrls.push(url);
                }
            }
        }
        return filteredResults;
    }, [getLink]);

    useEffect(() => {
        if (term.length > minLength) {
            setLoading(true);
            let source = axios.CancelToken.source();
            api.get('search', { term }, { cancelToken: source.token }).then(response => {
                log.action('Search', term, 'search', {
                    search_term: term
                });
                setResults(getFilteredResults(response.data.response.results));
                setLoading(false);
                api.setCache(response);
            }).catch(error => {
                api.error(error);
            });
            return () => source.cancel();
        } else {
            setResults([]);
        }
    }, [term, getFilteredResults]);
    useEffect(() => {
        if (props.searchOpen) {
            searchInputRef.current.focus();
        }
    }, [props.searchOpen]);
    useEffect(() => {
        const setKeydown = (event) => {
            const dropdownFocused = searchInputRef.current && document.activeElement === searchInputRef.current;
            const upDown = dropdownFocused && ['ArrowDown', 'ArrowUp'].includes(event.code);
            if (upDown) {
                event.preventDefault();
            }
            if (results.length) {
                if (['Enter', 'NumpadEnter'].includes(event.code)) {
                    event.preventDefault();
                    if (activeIndex !== null) {
                        props.setSearchOpen(false);
                        menuContext.setMenuOpen(false);
                        history.push(results[activeIndex].url);
                    }
                } else if (event.code === 'Escape') {
                    props.setSearchOpen(false);
                } else if (upDown) {
                    return setActiveIndex(getNextIndex(event.code, activeIndex, results));
                }
            } else if (upDown) {
                props.setSearchOpen(true);
            }
        }
        document.addEventListener('keydown', setKeydown, false);
        return () => {
            document.removeEventListener('keydown', setKeydown, false);
        };
    }, [history, props, results, activeIndex, menuContext]);
    return (
        <>
        <form className={ 'header__search' + (props.searchOpen ? ' header__search--open' : '') + (permission.check('canAdminSwitch') ? ' header__search--switch' : '')  } ref={ props.forwardRef } onSubmit={ (event) => event.preventDefault() }>
            <input ref={ searchInputRef } className="header__search-input" placeholder="Search..." onChange={ doSearch } />
            <Icon className="header__search-icon" icon="Search"/>
            { term.length > minLength && getResults() }
        </form>
   </>
    );
}
export default HeaderSearch;