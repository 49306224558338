import React from 'react';
//import AuthContext from 'context/auth';
//import Button from 'components/ui/Button';
//import content from 'helper/content';
import log from 'helper/log';
//import { ReactComponent as Logo } from 'images/logo.svg';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        log.error('ReactError', error.name + ': ' + error.message, {stack: info.componentStack});
    }

    render() {
        if (this.state.hasError) {
            return '';
            /*return (
                <div className="splash-screen splash-screen--error-boundary">
                    <div className="splash-screen__inner">
                        <div className="splash-screen__inner-logo"><Logo/></div>
                        <div className="splash-screen__message">
                            <>
                                { content.html('errorBoundaryMessage',
                                    <>
                                        <h3>Sorry, something went wrong</h3>
                                        <p>Please click the reload button below and try again. If this issue continues, please contact us.</p>
                                    </>
                                ) }
                            </>
                            <Button onClick={() => {window.location.reload()}}>Reload</Button>
                            <Button onClick={(event) => {AuthContext.doLogOut(event)}}>Log out</Button>
                        </div>
                    </div>
                </div>
            );*/
        }

        return this.props.children;
    }
}

export default ErrorBoundary;