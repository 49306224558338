import React, { useContext, useEffect } from 'react';
import ReactGA4 from 'react-ga4';

import TagManager from 'react-gtm-module'

import 'scss/index.scss';
import 'scss/component/print/print.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import ErrorBoundary from 'components/ui/ErrorBoundary';
import Layout from 'components/ui/Layout';
import LiveChat from 'components/ui/LiveChat';
import AuthContext from 'context/auth';
import UserContext from 'context/user';
import { ReactComponent as Logo } from 'images/logo.svg';
import Loading from 'components/ui/Loading';
import ConfigContext, { isMaintenanceMode, getMaintenanceMessage } from 'context/config';
import content from 'helper/content';
import { getHtml } from 'helper/html';
import debug from 'helper/debug';
import { getCookieValue } from 'helper/cookie';

let analyticsStorage = getCookieValue('analytics') ? 'granted' : 'denied';
let marketingStorage = getCookieValue('marketing') ? 'granted' : 'denied';
ReactGA4.gtag('consent', 'default', {
    'ad_storage': marketingStorage,
    'ad_personalization': marketingStorage,
    'ad_user_data': marketingStorage,
    'analytics_storage': analyticsStorage,
    'functionality_storage': 'granted',
    'personalization_storage': marketingStorage,
    'security_storage': 'granted'
});
ReactGA4.gtag('set', 'url_passthrough', true);
ReactGA4.gtag('set', 'ads_data_redaction', true);
if (process.env.REACT_APP_GA4_ID) {
    ReactGA4.initialize(process.env.REACT_APP_GA4_ID);
}
if (process.env.REACT_APP_GTM_ID && getCookieValue('marketing')) {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID })
}

const App = () => {
    const userContext = useContext(UserContext);
    const authContext = useContext(AuthContext);
    const configContext = useContext(ConfigContext);
    const authorised = authContext.authenticated && !userContext.user.isDefault;
    const marketingCookiesAllowed = !! getCookieValue('marketing');
    useEffect(() => {
        if (marketingCookiesAllowed && process.env.REACT_APP_JQUERY_VERSION && process.env.REACT_APP_CONVERTEXPERIMENTS_ID) {
            const jqueryScript = document.createElement('script');
            jqueryScript.src = "https://cdnjs.cloudflare.com/ajax/libs/jquery/" + process.env.REACT_APP_JQUERY_VERSION + "/jquery.slim.min.js";
            jqueryScript.defer = true;
            jqueryScript.crossOrigin = 'anonymous';
            jqueryScript.referrerPolicy = 'no-referrer';
            jqueryScript.integrity = process.env.REACT_APP_JQUERY_INTEGRITY_HASH ? process.env.REACT_APP_JQUERY_INTEGRITY_HASH : '';
            document.body.appendChild(jqueryScript);

            const convertScript = document.createElement('script');
            convertScript.src = "https://cdn-3.convertexperiments.com/js/" + process.env.REACT_APP_CONVERTEXPERIMENTS_ID + ".js";
            convertScript.defer = true;
            document.body.appendChild(convertScript);

            return () => {
                document.body.removeChild(convertScript);
                document.body.removeChild(jqueryScript);
            }
        }
    }, [marketingCookiesAllowed]);
    const showSplashScreen = () => {
        if (userContext.switching) {
            return true;
        }
        // App is loaded and can be displayed, routes are > 1 (not just the login route) and the user is logged in and set or not logged in
        if (authContext.csrf && !configContext.failedToLoad && configContext.routeConfig.length > 1 && (authorised || !authContext.authenticated)) {
            return false;
        }
        return true;
    }
    const getSplashScreenMessage = () => {
        if (configContext.failedToLoad === true) {
            debug.error('Dev mode error: Failed to load message/routes');
            return <p className="splash-screen__message">{ content.text('failedToLoad', 'Failed to load, please try again later') }</p>
        }
        return null;
    }
    const splashScreenMessage = getSplashScreenMessage();
    const splashScreen = (
        <div className={ 'splash-screen' + (!showSplashScreen() ? ' splash-screen--loaded' : '') }>
            <div className="splash-screen__inner">
                <div className="splash-screen__inner-logo"><Logo/></div>
                { !splashScreenMessage && <Loading size="40" strokeSize="3"/> }
                { splashScreenMessage }
                { userContext.switching && <div className="splash-screen__message"><p>Switching account</p></div> }
            </div>
        </div>
    );
    if (isMaintenanceMode(configContext.messages)) {
        const message = getMaintenanceMessage(configContext.messages);
        return (
            <div className="splash-screen splash-screen--maintenance">
                <div className="splash-screen__inner">
                    <div className="splash-screen__inner-logo"><Logo/></div>
                    <div className="splash-screen__inner-message">
                        <h3>{ message.title }</h3>
                        { getHtml(message.text) }
                    </div>
                </div>
            </div>
        );
    }
    return (
        <ErrorBoundary>
            { splashScreen }
            { !showSplashScreen() &&
                <>
                    <Router>
                        <Layout />
                    </Router>
                    <LiveChat authorised={ authorised } />
                </>
            }
        </ErrorBoundary>
    );
}
export default App;