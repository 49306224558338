import React from 'react';
import { getAllowedAttributes } from 'helper/form/fields';

const Checkbox = (props) => {
    const allowedAttributes = [
        'name',
        'value',
        'disabled',
        ...props.allowedAttributes
    ];
    const attributes = getAllowedAttributes(props, allowedAttributes);
    const onChangeHandler = (typeof props.inputChangeHandler === 'function')
        ? props.inputChangeHandler
        : e => {props.value = e.target.checked ? '1' : '0'};
    return (
        <div className="form__field-checkbox">
            <input type="checkbox"
                   id={ props.name }
                   onChange={ onChangeHandler }
                   onFocus={ props.inputChangeHandler }
                   checked={ props.value === '1' }
                   value="1"
                   { ...attributes } />
            <label htmlFor={ props.name }>{ props.label }</label>
        </div>
    );
};

export default Checkbox;